
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

import { setCurrentUser, getCurrentUser } from '../../utils/index';
import { UserRole } from '../../utils/auth.roles';

import RegisterAccountModalEC from "@/views/auth/RegisterModalEC.vue";

import RegisterAccountModal from "@/views/auth/RegisterModal.vue";

import SignInOptionModal from "./SignInOptionModal.vue";

import {login}  from "@/api/login";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    RegisterAccountModalEC,
    RegisterAccountModal 
  },
  data() {
      return { 
        email: '',
        password: ''
      };
  }, 
  methods: {
    
    async onEnter(){ 
            this.login(); 
    },
    async login(){ 
        const response = await login({u: this.email, p: this.password, pt : "ec"});
        
        if(response.data !== undefined) {
            if(response.data.success === false){
                  Swal.fire({
                    title: "",
                    text: response.data.message,
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary"
                  }); 
                  return; 
            }
            
            if(response.data.success === true){
                  let avatar = response.data.avatar;
            			if(response.data.avatar.indexOf("default-avatar.png") > 0 || response.data.avatar === "") {
										avatar = "https://images.empoweredconsumerism-static-files.com/avatar/default-avatar.png";
									}

                localStorage.setItem("pVaAcNkPaOgGeItRyEpAeL", response.data.packageType);

                const user = {
                    un: this.email,
                    fname: response.data.fname,
                    mname: response.data.mname,
                    lname: response.data.lname,
                    avatar: avatar,
                    role: UserRole.User,
                    usid: response.data.usid,
                    email: response.data.email,
                    address: response.data.address,
                    bday: response.data.bday,
                    mobile: response.data.mobile
                };
                setCurrentUser(user);

              const authorization = response.data.authorization;
              localStorage.ecmtctoken = authorization; 
              localStorage.ec_current_usid = response.data.usid;
              // if(response.data.isBIBO_ONLY_user == true){
              //     localStorage.setItem("bibo-only","true");
              // } else {
              //     localStorage.setItem("bibo-only","false");
              // }

              
              if(response.data.isShowAutoPMA == true){
                localStorage.setItem("sXhYoZwAABuCtDoEPFMGA", "true");
              } else {
                localStorage.setItem("sXhYoZwAABuCtDoEPFMGA", "false");
              }
              
              // localStorage.setItem("oZwAuCtDoAYBEPsXhFMGA", response.data.isShowBibo);
              localStorage.setItem("bibo-only","false");

              
              setTimeout(() => { 
                  // this.$router.push('/dashboardecp/reminderspage') ;
                  this.$router.push("/estore");
              }, 500);

            }
            
        }
    },

  },
  mounted() {
    document.title = "EMPOWERED CONSUMERISM";
    let u : any = this.$route.query.u; 
    let p : any = this.$route.query.p;
    if(u !== undefined && u !== null && p !== undefined && p !== null) { 
      u = atob(u);
      p = atob(p);
      this.email  = u;
      this.password = p;
      this.login();
    }
  }
});
